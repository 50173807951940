<template>
	<div style="background-color: transparent;" class="c-app flex-row align-items-center">
		<CContainer>
			<CRow class="justify-content-center">
				<CCol sm="7">
					<CCard class="pr-4 pl-4 pt-3 pb-0 mb-0" style="min-width: 700px; height: 400px">	
                        <router-view></router-view>					
					</CCard>											
				</CCol>
			</CRow>
		</CContainer>
	</div>
</template>

<script>
export default {
}
</script>